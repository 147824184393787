import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import './App.css';
import axios from 'axios';



function App() {
  const [email, setEmail] = useState('');
  const [isJoined, setIsJoined] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Call submitEmail to send the email to the backend
    await submitEmail(email);

    console.log('Email submitted:', email);

    // Set the state variables after the email has been submitted
    setEmail('');
    setIsJoined(true);
    setShowConfetti(true);
    setShowConfirmation(true);
};


  const submitEmail = async (email) => {
    try {
        const response = await axios.post('https://prcoach.ca/submit_email', { email });
        console.log(response.data.message);
    } catch (error) {
        console.error("Error storing email", error);
    }
};

  useEffect(() => {
    if (isJoined) {
      setTimeout(() => {
        setIsJoined(false);
        setShowConfetti(false);
        setShowConfirmation(false);
      }, 10000);
    }
  }, [isJoined]);

  return (
    <div className="App">
      <header className="App-header">
        <img src="logo_PRCoach.png" alt="Waitlist" className="App-image"/>
        <h2 className="Launch-date">Launching Fall 2024</h2>
        {showConfetti && <Confetti />}
        {!isJoined && (
          <form onSubmit={handleSubmit}>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Enter your email address"
              className="Email-input"
              required 
            />
            <button 
              type="submit" 
              className={`Join-button ${isJoined ? 'Animated-feedback' : ''}`}
              disabled={isJoined}
            >
              JOIN WAITLIST
            </button>
          </form>
        )}
        {showConfirmation && (
          <div className="Confirmation-message">
            <h3>Thank you for joining!</h3>
            <p>You will be notified when the app is available.</p>
          </div>
        )}
      </header>
    </div>
  );
}

export default App;
